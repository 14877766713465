

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL4PostLab',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4_1: null,
        part4_2: null,
        part4_3: null,
        part4_4: null,
        part4_5: null,
      },
      questions: [
        {
          text: 'a) What is Mr. Humphrey trying to identify in this scenario?',
          inputName: 'part4_1',
        },
        {
          text: 'b) What measurements did Humphrey gather in order to try and figure out the composition of the material?',
          inputName: 'part4_2',
        },
        {
          text: "c) What operations could you perform to help with Mr. Humphrey's problem?",
          inputName: 'part4_3',
        },
        {
          text: "d) What measurements would you need from Mr. Humphrey's in order to help with the problem of identifying the piece?",
          inputName: 'part4_4',
        },
        {
          text: 'e) If the results of the data analysis showed that there is 1) more than one possible material, or 2) no conclusive material, what could you do to further determine the identity of the rocket piece?',
          inputName: 'part4_5',
        },
      ],
    };
  },
};
